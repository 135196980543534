<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.94 11.26">
    <path
      d="m6.58,6.89V2.47m0,4.42l3.37,1.93c.22.13.5-.03.5-.29V.83c0-.25-.27-.41-.5-.29l-3.37,1.93m0,4.42h-3.87c-1.22,0-2.21-.99-2.21-2.21h0c0-1.22.99-2.21,2.21-2.21h3.87"
      style="fill:none; stroke:#999; stroke-linecap:round; stroke-linejoin:round;" />
    <path d="m3.13,9.82l-.42-2.93h2.21l.37,2.62c.09.66-.42,1.25-1.08,1.25-.54,0-1.01-.4-1.08-.94Z"
          style="fill:none; stroke:#999; stroke-linecap:round; stroke-linejoin:round;" />
  </svg>
</template>

<style scoped></style>
